import React, { Component } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Switch, Route } from "react-router-dom";

import "./index.scss";
import DarkMainDemo from "./Main";
import error404 from "./elements/error404";

import * as serviceWorker from "./serviceWorker";
import LegalPage from "./elements/LegalPage";

class Root extends Component {
  render() {
    return (
      <BrowserRouter basename={"/"}>
        <Switch>
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/`}
            component={DarkMainDemo}
          />

          <Route exact path={`${process.env.PUBLIC_URL}/imprint`} component={LegalPage}/>
          <Route exact path={`${process.env.PUBLIC_URL}/privacy-policy`} component={LegalPage}/>

          <Route path={`${process.env.PUBLIC_URL}/404`} component={error404} />
          <Route component={error404} />
        </Switch>
      </BrowserRouter>
    );
  }
}

ReactDOM.render(<Root />, document.getElementById("root"));
serviceWorker.register();

import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Header from "../component/Header";
import Footer from "../component/footer/Footer";

const legalPages = {
    imprint: {
        title: 'Imprint',
        content: <>
            <h2>Impressum</h2>
            <p>
                Tobias Lindenberg <br />
                Lohmühlenweg 35<br />
                D-53881 Euskirchen<br />
            </p>
            <h3>Kontakt</h3>
            <p>
                Telefon: +49 (0) 177 626 737 5 <br />
                E-Mail: info@project-tampah.com
            </p>
            <h3>Umsatzsteuer-ID</h3>
            <p>
                Umsatzsteuer-Identifikationsnummer gemäß §27 a Umsatzsteuergesetz: <br />
                DE 324 540 519
            </p>
            <p>Plattform der EU-Kommission zur Online-Streitbeilegung: <a href="https://ec.europa.eu/odr">https://ec.europa.eu/odr</a></p>
            <p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>
        </>
    },
    'privacy-policy': {
        title: 'Privacy Policy',
        content: <>
            <div>
                <h3>1) Information &uuml;ber die Erhebung personenbezogener Daten und Kontaktdaten des Verantwortlichen</h3>

                <p><b>1.1</b>&thinsp;Wir freuen uns, dass Sie unsere Website besuchen und bedanken uns f&uuml;r Ihr Interesse. Im Folgenden informieren wir Sie &uuml;ber den Umgang mit Ihren personenbezogenen Daten bei der Nutzung unserer Website. Personenbezogene Daten sind hierbei alle Daten, mit denen Sie pers&ouml;nlich identifiziert werden k&ouml;nnen.</p>

                <p><b>1.2</b>&thinsp;Verantwortlicher f&uuml;r die Datenverarbeitung auf dieser Website im Sinne der Datenschutz-Grundverordnung (DSGVO) ist Tobias Lindenberg, Lohm&uuml;hlenweg 35, 53881 Euskirchen, Deutschland, Tel.: +49 (0) 177 6267375, E-Mail: tobias@project-tampah.com. Der f&uuml;r die Verarbeitung von personenbezogenen Daten Verantwortliche ist diejenige nat&uuml;rliche oder juristische Person, die allein oder gemeinsam mit anderen &uuml;ber die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten entscheidet.</p>

                <p><b>1.3</b>&thinsp;Diese Website nutzt aus Sicherheitsgr&uuml;nden und zum Schutz der &Uuml;bertragung personenbezogener Daten und anderer vertraulicher Inhalte (z.B. Bestellungen oder Anfragen an den Verantwortlichen) eine SSL-bzw. TLS-Verschl&uuml;sselung. Sie k&ouml;nnen eine verschl&uuml;sselte Verbindung an der Zeichenfolge &bdquo;https://&ldquo; und dem Schloss-Symbol in Ihrer Browserzeile erkennen.</p>

                <h3>2) Datenerfassung beim Besuch unserer Website</h3>

                <p>Bei der blo&szlig; informatorischen Nutzung unserer Website, also wenn Sie sich nicht registrieren oder uns anderweitig Informationen &uuml;bermitteln, erheben wir nur solche Daten, die Ihr Browser an unseren Server &uuml;bermittelt (sog. &bdquo;Server-Logfiles&ldquo;). Wenn Sie unsere Website aufrufen, erheben wir die folgenden Daten, die f&uuml;r uns technisch erforderlich sind, um Ihnen die Website anzuzeigen:</p>

                <ul>
                    <li>Unsere besuchte Website</li>
                    <li>Datum und Uhrzeit zum Zeitpunkt des Zugriffes</li>
                    <li>Menge der gesendeten Daten in Byte</li>
                    <li>Quelle/Verweis, von welchem Sie auf die Seite gelangten</li>
                    <li>Verwendeter Browser</li>
                    <li>Verwendetes Betriebssystem</li>
                    <li>Verwendete IP-Adresse (ggf.: in anonymisierter Form)</li>
                </ul>

                <p>Die Verarbeitung erfolgt gem&auml;&szlig; Art. 6 Abs. 1 lit. f DSGVO auf Basis unseres berechtigten Interesses an der Verbesserung der Stabilit&auml;t und Funktionalit&auml;t unserer Website. Eine Weitergabe oder anderweitige Verwendung der Daten findet nicht statt. Wir behalten uns allerdings vor, die Server-Logfiles nachtr&auml;glich zu &uuml;berpr&uuml;fen, sollten konkrete Anhaltspunkte auf eine rechtswidrige Nutzung hinweisen.</p>

                <h3>3) Content Delivery Network</h3>

                <p>AWS-CloudFront (Amazon)<br />
Auf unserer Website verwenden wir das Content Delivery Network (&quot;CDN&quot;) &bdquo;AWS CloudFront&ldquo; des Dienstanbieters &bdquo;Amazon&ldquo; (Amazon EU S.a. r.l., 38 avenue John F. Kennedy, L-1855 Luxemburg).<br />
Bei einem Content Delivery Network handelt es sich um einen Online-Dienst, mit dessen Hilfe insbesondere gro&szlig;e Mediendateien (wie z.B. Grafiken, Seiteninhalte oder Skripte) durch ein Netz regional verteilter und &uuml;ber das Internet verbundener Server ausgeliefert werden. Der Einsatz des Content Delivery Network von Amazon hilft uns bei der Optimierung der Ladegeschwindigkeiten unserer Website.<br />
Die Verarbeitung erfolgt gem&auml;&szlig; Art. 6 Abs. 1 lit. f DSGVO auf Basis unseres berechtigten Interesses an einer sicheren und effizienten Bereitstellung, sowie Verbesserung der Stabilit&auml;t und Funktionalit&auml;t unserer Website.<br />
Weitere Informationen finden Sie in der Datenschutzerkl&auml;rung von Amazon unter https://docs.aws.amazon.com/de_de/AmazonCloudFront/latest/DeveloperGuide/data-protection-summary.html</p>

                <h3>4) Kontaktaufnahme</h3>

                <p>Im Rahmen der Kontaktaufnahme mit uns (z.B. per Kontaktformular oder E-Mail) werden personenbezogene Daten erhoben. Welche Daten im Falle eines Kontaktformulars erhoben werden, ist aus dem jeweiligen Kontaktformular ersichtlich. Diese Daten werden ausschlie&szlig;lich zum Zweck der Beantwortung Ihres Anliegens bzw. f&uuml;r die Kontaktaufnahme und die damit verbundene technische Administration gespeichert und verwendet. Rechtsgrundlage f&uuml;r die Verarbeitung dieser Daten ist unser berechtigtes Interesse an der Beantwortung Ihres Anliegens gem&auml;&szlig; Art. 6 Abs. 1 lit. f DSGVO. Zielt Ihre Kontaktierung auf den Abschluss eines Vertrages ab, so ist zus&auml;tzliche Rechtsgrundlage f&uuml;r die Verarbeitung Art. 6 Abs. 1 lit. b DSGVO. Ihre Daten werden nach abschlie&szlig;ender Bearbeitung Ihrer Anfrage gel&ouml;scht. Dies ist der Fall, wenn sich aus den Umst&auml;nden entnehmen l&auml;sst, dass der betroffene Sachverhalt abschlie&szlig;end gekl&auml;rt ist und sofern keine gesetzlichen Aufbewahrungspflichten entgegenstehen.</p>

                <h3>5) Rechte des Betroffenen</h3>

                <p><b>5.1</b>&thinsp;Das geltende Datenschutzrecht gew&auml;hrt Ihnen gegen&uuml;ber dem Verantwortlichen hinsichtlich der Verarbeitung Ihrer personenbezogenen Daten umfassende Betroffenenrechte (Auskunfts- und Interventionsrechte), &uuml;ber die wir Sie nachstehend informieren:</p>

                <ul>
                    <li>Auskunftsrecht gem&auml;&szlig; Art. 15 DSGVO: Sie haben insbesondere ein Recht auf Auskunft &uuml;ber Ihre von uns verarbeiteten personenbezogenen Daten, die Verarbeitungszwecke, die Kategorien der verarbeiteten personenbezogenen Daten, die Empf&auml;nger oder Kategorien von Empf&auml;ngern, gegen&uuml;ber denen Ihre Daten offengelegt wurden oder werden, die geplante Speicherdauer bzw. die Kriterien f&uuml;r die Festlegung der Speicherdauer, das Bestehen eines Rechts auf Berichtigung, L&ouml;schung, Einschr&auml;nkung der Verarbeitung, Widerspruch gegen die Verarbeitung, Beschwerde bei einer Aufsichtsbeh&ouml;rde, die Herkunft Ihrer Daten, wenn diese nicht durch uns bei Ihnen erhoben wurden, das Bestehen einer automatisierten Entscheidungsfindung einschlie&szlig;lich Profiling und ggf. aussagekr&auml;ftige Informationen &uuml;ber die involvierte Logik und die Sie betreffende Tragweite und die angestrebten Auswirkungen einer solchen Verarbeitung, sowie Ihr Recht auf Unterrichtung, welche Garantien gem&auml;&szlig; Art. 46 DSGVO bei Weiterleitung Ihrer Daten in Drittl&auml;nder bestehen;</li>
                    <li>Recht auf Berichtigung gem&auml;&szlig; Art. 16 DSGVO: Sie haben ein Recht auf unverz&uuml;gliche Berichtigung Sie betreffender unrichtiger Daten und/oder Vervollst&auml;ndigung Ihrer bei uns gespeicherten unvollst&auml;ndigen Daten;</li>
                    <li>Recht auf L&ouml;schung gem&auml;&szlig; Art. 17 DSGVO: Sie haben das Recht, die L&ouml;schung Ihrer personenbezogenen Daten bei Vorliegen der Voraussetzungen des Art. 17 Abs. 1 DSGVO zu verlangen. Dieses Recht besteht jedoch insbesondere dann nicht, wenn die Verarbeitung zur Aus&uuml;bung des Rechts auf freie Meinungs&auml;u&szlig;erung und Information, zur Erf&uuml;llung einer rechtlichen Verpflichtung, aus Gr&uuml;nden des &ouml;ffentlichen Interesses oder zur Geltendmachung, Aus&uuml;bung oder Verteidigung von Rechtsanspr&uuml;chen erforderlich ist;</li>
                    <li>Recht auf Einschr&auml;nkung der Verarbeitung gem&auml;&szlig; Art. 18 DSGVO: Sie haben das Recht, die Einschr&auml;nkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen, solange die von Ihnen bestrittene Richtigkeit Ihrer Daten &uuml;berpr&uuml;ft wird, wenn Sie eine L&ouml;schung Ihrer Daten wegen unzul&auml;ssiger Datenverarbeitung ablehnen und stattdessen die Einschr&auml;nkung der Verarbeitung Ihrer Daten verlangen, wenn Sie Ihre Daten zur Geltendmachung, Aus&uuml;bung oder Verteidigung von Rechtsanspr&uuml;chen ben&ouml;tigen, nachdem wir diese Daten nach Zweckerreichung nicht mehr ben&ouml;tigen oder wenn Sie Widerspruch aus Gr&uuml;nden Ihrer besonderen Situation eingelegt haben, solange noch nicht feststeht, ob unsere berechtigten Gr&uuml;nde &uuml;berwiegen;</li>
                    <li>Recht auf Unterrichtung gem&auml;&szlig; Art. 19 DSGVO: Haben Sie das Recht auf Berichtigung, L&ouml;schung oder Einschr&auml;nkung der Verarbeitung gegen&uuml;ber dem Verantwortlichen geltend gemacht, ist dieser verpflichtet, allen Empf&auml;ngern, denen die Sie betreffenden personenbezogenen Daten offengelegt wurden, diese Berichtigung oder L&ouml;schung der Daten oder Einschr&auml;nkung der Verarbeitung mitzuteilen, es sei denn, dies erweist sich als unm&ouml;glich oder ist mit einem unverh&auml;ltnism&auml;&szlig;igen Aufwand verbunden. Ihnen steht das Recht zu, &uuml;ber diese Empf&auml;nger unterrichtet zu werden.</li>
                    <li>Recht auf Daten&uuml;bertragbarkeit gem&auml;&szlig; Art. 20 DSGVO: Sie haben das Recht, Ihre personenbezogenen Daten, die Sie uns bereitgestellt haben, in einem strukturierten, g&auml;ngigen und maschinenlesebaren Format zu erhalten oder die &Uuml;bermittlung an einen anderen Verantwortlichen zu verlangen, soweit dies technisch machbar ist;</li>
                    <li>Recht auf Widerruf erteilter Einwilligungen gem&auml;&szlig; Art. 7 Abs. 3 DSGVO: Sie haben das Recht, eine einmal erteilte Einwilligung in die Verarbeitung von Daten jederzeit mit Wirkung f&uuml;r die Zukunft zu widerrufen. Im Falle des Widerrufs werden wir die betroffenen Daten unverz&uuml;glich l&ouml;schen, sofern eine weitere Verarbeitung nicht auf eine Rechtsgrundlage zur einwilligungslosen Verarbeitung gest&uuml;tzt werden kann. Durch den Widerruf der Einwilligung wird die Rechtm&auml;&szlig;igkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung nicht ber&uuml;hrt;</li>
                    <li>Recht auf Beschwerde gem&auml;&szlig; Art. 77 DSGVO: Wenn Sie der Ansicht sind, dass die Verarbeitung der Sie betreffenden personenbezogenen Daten gegen die DSGVO verst&ouml;&szlig;t, haben Sie - unbeschadet eines anderweitigen verwaltungsrechtlichen oder gerichtlichen Rechtsbehelfs - das Recht auf Beschwerde bei einer Aufsichtsbeh&ouml;rde, insbesondere in dem Mitgliedstaat Ihres Aufenthaltsortes, Ihres Arbeitsplatzes oder des Ortes des mutma&szlig;lichen Versto&szlig;es.</li>
                </ul>

                <p><b>5.2</b>&thinsp;WIDERSPRUCHSRECHT</p>

                <p>WENN WIR IM RAHMEN EINER INTERESSENABW&Auml;GUNG IHRE PERSONENBEZOGENEN DATEN AUFGRUND UNSERES &Uuml;BERWIEGENDEN BERECHTIGTEN INTERESSES VERARBEITEN, HABEN SIE DAS JEDERZEITIGE RECHT, AUS GR&Uuml;NDEN, DIE SICH AUS IHRER BESONDEREN SITUATION ERGEBEN, GEGEN DIESE VERARBEITUNG WIDERSPRUCH MIT WIRKUNG F&Uuml;R DIE ZUKUNFT EINZULEGEN.<br />
MACHEN SIE VON IHREM WIDERSPRUCHSRECHT GEBRAUCH, BEENDEN WIR DIE VERARBEITUNG DER BETROFFENEN DATEN. EINE WEITERVERARBEITUNG BLEIBT ABER VORBEHALTEN, WENN WIR ZWINGENDE SCHUTZW&Uuml;RDIGE GR&Uuml;NDE F&Uuml;R DIE VERARBEITUNG NACHWEISEN K&Ouml;NNEN, DIE IHRE INTERESSEN, GRUNDRECHTE UND GRUNDFREIHEITEN &Uuml;BERWIEGEN, ODER WENN DIE VERARBEITUNG DER GELTENDMACHUNG, AUS&Uuml;BUNG ODER VERTEIDIGUNG VON RECHTSANSPR&Uuml;CHEN DIENT.</p>

                <p>WERDEN IHRE PERSONENBEZOGENEN DATEN VON UNS VERARBEITET, UM DIREKTWERBUNG ZU BETREIBEN, HABEN SIE DAS RECHT, JEDERZEIT WIDERSPRUCH GEGEN DIE VERARBEITUNG SIE BETREFFENDER PERSONENBEZOGENER DATEN ZUM ZWECKE DERARTIGER WERBUNG EINZULEGEN. SIE K&Ouml;NNEN DEN WIDERSPRUCH WIE OBEN BESCHRIEBEN AUS&Uuml;BEN.</p>

                <p>MACHEN SIE VON IHREM WIDERSPRUCHSRECHT GEBRAUCH, BEENDEN WIR DIE VERARBEITUNG DER BETROFFENEN DATEN ZU DIREKTWERBEZWECKEN.</p>

                <h3>6) Dauer der Speicherung personenbezogener Daten</h3>

                <p>Die Dauer der Speicherung von personenbezogenen Daten bemisst sich anhand der jeweiligen Rechtsgrundlage, am Verarbeitungszweck und &ndash; sofern einschl&auml;gig &ndash; zus&auml;tzlich anhand der jeweiligen gesetzlichen Aufbewahrungsfrist (z.B. handels- und steuerrechtliche Aufbewahrungsfristen).</p>

                <p>Bei der Verarbeitung von personenbezogenen Daten auf Grundlage einer ausdr&uuml;cklichen Einwilligung gem&auml;&szlig; Art. 6 Abs. 1 lit. a DSGVO werden diese Daten so lange gespeichert, bis der Betroffene seine Einwilligung widerruft.</p>

                <p>Existieren gesetzliche Aufbewahrungsfristen f&uuml;r Daten, die im Rahmen rechtsgesch&auml;ftlicher bzw. rechtsgesch&auml;fts&auml;hnlicher Verpflichtungen auf der Grundlage von Art. 6 Abs. 1 lit. b DSGVO verarbeitet werden, werden diese Daten nach Ablauf der Aufbewahrungsfristen routinem&auml;&szlig;ig gel&ouml;scht, sofern sie nicht mehr zur Vertragserf&uuml;llung oder Vertragsanbahnung erforderlich sind und/oder unsererseits kein berechtigtes Interesse an der Weiterspeicherung fortbesteht.</p>

                <p>Bei der Verarbeitung von personenbezogenen Daten auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO werden diese Daten so lange gespeichert, bis der Betroffene sein Widerspruchsrecht nach Art. 21 Abs. 1 DSGVO aus&uuml;bt, es sei denn, wir k&ouml;nnen zwingende schutzw&uuml;rdige Gr&uuml;nde f&uuml;r die Verarbeitung nachweisen, die die Interessen, Rechte und Freiheiten der betroffenen Person &uuml;berwiegen, oder die Verarbeitung dient der Geltendmachung, Aus&uuml;bung oder Verteidigung von Rechtsanspr&uuml;chen.</p>

                <p>Bei der Verarbeitung von personenbezogenen Daten zum Zwecke der Direktwerbung auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO werden diese Daten so lange gespeichert, bis der Betroffene sein Widerspruchsrecht nach Art. 21 Abs. 2 DSGVO aus&uuml;bt.</p>

                <p>Sofern sich aus den sonstigen Informationen dieser Erkl&auml;rung &uuml;ber spezifische Verarbeitungssituationen nichts anderes ergibt, werden gespeicherte personenbezogene Daten im &Uuml;brigen dann gel&ouml;scht, wenn sie f&uuml;r die Zwecke, f&uuml;r die sie erhoben oder auf sonstige Weise verarbeitet wurden, nicht mehr notwendig sind.</p>
            </div>
        </>
    },
};

class LegalPage extends Component {
    render() {
        const currentPage = legalPages[this.props.location.pathname.replace('/', '')];

        return (
            <React.Fragment>
                <PageHelmet pageTitle='Impressum' />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--1" data-black-overlay="5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">{currentPage.title}</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="rn-service-details ptb--80 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="service-details-inner">
                                    <div className="inner">
                                        <div className="row sercice-details-content pb--80 align-items-center">
                                            <div className="details mt_md--30 mt_sm--30">
                                                {currentPage.content}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Footer />
            </React.Fragment>
        )
    }
}
export default LegalPage;

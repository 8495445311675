import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { data } from "../data";

class Testimonial extends Component {
    render() {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-lg-12">
                        <Tabs>
                            {data.testimonial.list.map((val, i) => (
                                <TabPanel key={i}>
                                    <div className="rn-testimonial-content text-center">
                                        <div className="inner">
                                            <p>{val.description}</p>
                                        </div>
                                        <div className="author-info">
                                            <h6 dangerouslySetInnerHTML={{ __html: val.name }} />
                                        </div>
                                    </div>
                                </TabPanel>
                            ))}

                            <TabList className="testimonial-thumb-wrapper">
                                {data.testimonial.images.map((val, i) => (
                                    <Tab key={i}>
                                        <div className="testimonial-thumbnai">
                                            <div className="thumb">
                                                <img src={val} alt="Testimonial Images" />
                                            </div>
                                        </div>
                                    </Tab>
                                ))}
                            </TabList>
                        </Tabs>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default Testimonial;

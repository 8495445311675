import React, { Component } from "react";
import { data } from "../data";

class Services extends Component {
    render() {
        return (
            <div className="slider-activation">
                {/* Start Single Slide */}
                <div className="slide slide-style-1 slider-fixed--height d-flex align-items-center bg_image bg_image--1" data-black-overlay="6">
                    <div className="container position-relative">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner">
                                    <h1 className="title theme-gradient">A digital <br /> agency. </h1>
                                </div>
                            </div>
                        </div>
                        {/* Start Service Area */}
                        <div className="service-wrapper service-white">
                            <div className="row">
                                {data.services.map((val, i) => (
                                    <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                        <div className="service service__style--1">
                                            <div className="icon">
                                                <img src={`/assets/images/icons/icon-${val.icon}.png`} alt="Digital Agency" />
                                            </div>
                                            <div className="content">
                                                <h4 className="title">{val.title}</h4>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        {/* End Service Area */}
                    </div>
                </div>
                {/* End Single Slide */}
            </div>
        );
    }
}

export default Services;

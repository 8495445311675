import React, { Component } from "react";
import Slider from "react-slick";
import { data } from "../data";
import { portfolioSlick } from "../page-demo/script";

class Portfolio extends Component {
    render() {
        return (
            <React.Fragment>
                <div className="portfolio-wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="section-title">
                                    <h2>{data.portfolio.title}</h2>
                                    <p>{data.portfolio.description}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="portfolio-slick-activation mt--70 mt_sm--40">
                        <Slider {...portfolioSlick}>
                            {data.portfolio.list.map((val, i) => (
                                <div className="portfolio" key={i}>
                                    <div className="thumbnail-inner">
                                        <div className={`thumbnail ${val.image}`}></div>
                                    </div>
                                    <div className="content">
                                        <div className="inner">
                                            <p>{val.category}</p>
                                            <h4><a href={val.link}>{val.title}</a></h4>
                                            <div className="portfolio-button">
                                                <a className="rn-btn" href={val.link}>View Project</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default Portfolio;

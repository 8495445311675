import React, { Component } from "react";
import { data } from "../data";

class Brand extends Component {
    render() {
        return (
            <React.Fragment>
                <ul className="brand-style-2">
                    {data.testimonial.brand.map((item, i) => (
                        <li key={i}>
                            <a href={item.link}>
                                <img src={item.image} alt={item.alt} />
                            </a>
                        </li>
                    ))}
                </ul>
            </React.Fragment>
        )
    }
}

export default Brand;

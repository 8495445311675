import React, { Component, Fragment } from "react";
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
import { data } from "../data";

class FunFactsCount extends Component {
    state = {
        didViewCountUp: false
    };

    onVisibilityChange = isVisible => {
        if (isVisible) {
            this.setState({ didViewCountUp: true });
        }
    }

    render() {
        return (
            <Fragment>
                <div className="row">
                    {data.funfacts.map((val, i) => (
                        <div className="counterup_style--1 col-lg-4 col-md-4 col-sm-6 col-12" key={i}>
                            <h5 className="counter">
                                <VisibilitySensor onChange={this.onVisibilityChange} offset={{ top: 10 }} delayedCall>
                                    <CountUp end={this.state.didViewCountUp ? val.countNum : 0} />
                                </VisibilitySensor>
                            </h5>
                            <p className="description">{val.countTitle}</p>
                        </div>
                    ))}
                </div>
            </Fragment>
        )
    }
}

export default FunFactsCount;

import React from "react";
import { FaFacebookF, FaLinkedinIn, FaNodeJs, FaPython, FaReact } from "react-icons/fa";
import DjangoIcon from "./component/icons/django";
import GraphQLIcon from "./component/icons/graphql";
import NextJSIcon from "./component/icons/nextjs";

export const data = {
    contact: {
        email: [
            'info@project-tampah.com',
            'tobias@project-tampah.com'
        ],
        number: [
            '+49 177 6267375',
            '+49 171 2075573',
        ],
        address: [
            'Lohmühlenweg 35',
            'D-53881 Euskirchen',
        ]
    },
    copyright: `Copyright © ${(new Date().getFullYear())} Project Tampah. All Rights Reserved.`,
    social: [
        { social: <FaFacebookF />, link: 'https://www.facebook.com/Project-Tampah-101226561703911/' },
        { social: <FaLinkedinIn />, link: 'https://www.linkedin.com/company/project-tampah' },
    ],
    services: [
        {
            icon: '01',
            title: 'Affiliate Marketing',
            description: 'Ever since the foundation of the company Project Tampah has been working intensively with affiliate marketing. Even some own affiliate projects have been realized successfully.'
        },
        {
            icon: '03',
            title: 'Marketing & Analytics',
            description: 'The success of a website has to be measurable. The team of Project Tampah has developed special tools to calculate and evaluate these numbers.'
        },
        {
            icon: '02',
            title: 'Application Development',
            description: 'Project Tampah is not depending on programmed apps. The company develops everything by itself because digitalization is the future.'
        },
    ],
    about: {
        title: 'About',
        description: 'Project Tampah is a young agency with a place of business in Munich. Founded in 2018 we are constantly developing ourselves further. We love challenges! This is why we specialized on the difficult areas of affiliate marketing and application development. At the same time we are focused on you as our customer.',
        text: [
            {
                title: 'Who we are',
                description: 'We are a varied team of specialists. This includes developers, designers, writers and marketing experts. Whatever problem you have, we will find a solution. We work creative and don’t hesitate to go different ways to help you.',
            },
        ],
    },
    portfolio: {
        title: 'Our Works',
        description: 'Here is a short summary of our realized projects',
        list: [
            {
                image: 'gameservercheck',
                category: 'gameservercheck.com',
                title: 'Comparison and affiliate site around Gameserver',
                link: 'https://rootservercheck.com',
            },
            {
                image: 'rootservercheck',
                category: 'rootservercheck.com',
                title: 'Comparison and affiliate site around Rootserver',
                link: 'https://rootservercheck.com',
            },
            {
                image: 'waldkindergarten-bekleidung',
                category: 'waldkindergarten-bekleidung.de',
                title: 'Comparison and affiliate site around Waldkindergarten Bekleidung',
                link: 'https://waldkindergarten-bekleidung.de',
            },
            {
                image: 'streamingdienst-vergleich',
                category: 'streamingdienst-vergleich.de',
                title: 'Comparison and affiliate site around Streamingdienste',
                link: 'https://streamingdienst-vergleich.de',
            },
            {
                image: 'online-fitnessprogramm-vergleich',
                category: 'online-fitnessprogramm-vergleich.de',
                title: 'Comparison and affiliate site around Online Fitnessprograme',
                link: 'https://online-fitnessprogramm-vergleich.de',
            },
            {
                image: 'online-trading-vergleich',
                category: 'online-trading-vergleich.de',
                title: 'Comparison and affiliate site around Trading Plattformen',
                link: 'https://online-trading-vergleich.de',
            },
            {
                image: 'bankkonto-vergleichen',
                category: 'bankkonto-vergleichen.de',
                title: 'Comparison and affiliate site around Bankkonten',
                link: 'https://bankkonto-vergleichen.de',
            },
            {
                image: 'ratenkredit-finden',
                category: 'ratenkredit-finden.de',
                title: 'Comparison and affiliate site around Ratenkredite',
                link: 'https://ratenkredit-finden.de',
            },
            {
                image: 'vintageshop-vergleich',
                category: 'vintageshop-vergleich.de',
                title: 'Comparison and affiliate site around Vintageshops',
                link: 'https://vintageshop-vergleich.de',
            },
            {
                image: 'freelancer-vergleich',
                category: 'freelancer-vergleich.de',
                title: 'Comparison and affiliate site around Freelancer',
                link: 'https://freelancer-vergleich.de',
            },
            {
                image: 'smart-home-speaker-vergleich',
                category: 'smart-home-speaker-vergleich.de',
                title: 'Comparison and affiliate site around Smart Home Speaker',
                link: 'https://smart-home-speaker-vergleich.de',
            },
        ],
    },
    testimonial: {
        list: [
            // {
            //     name: '<span>Roberto Omezzolli</span> - CEO, OCIRIS GmbH',
            //     description: 'Fatima Asrafy standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections Bonorum et Malorum original.',
            // },
            {
                name: '<span>Nils Schäfer </span> - CMO, 4Players GmbH<br><small>Since 2014 found on gameservercheck.com</small>',
                description: 'For years Project Tampah has been a reliable partner, which we wouldn’t want to miss. The collaboration has been professional as well as uncomplicated - agreements are being held without limitation. We are looking forward to next years!',
            },
            {
                name: '<span>Andreas Emschanow</span> - CEO, Electronicx GmbH<br><small>Customer since 2018</small>',
                description: 'Project Tampah renewed our infrastructure with the newest archictecture and also keep it alive. 24/7 we receive professional support to be reachable for our customers.',
            },
        ],
        images: [
            '/assets/images/client/4players.png',
            '/assets/images/client/electronicx.png',
        ],
        brand: [
            {
                image: '/assets/images/brand/4players.png',
                alt: 'Logo 4Players',
                link: 'https://www.4netplayers.com/',
            },
            {
                image: '/assets/images/brand/happysuits.png',
                alt: 'Logo Happysuits',
                link: 'https://happysuits.de/',
            },
            {
                image: '/assets/images/brand/electronicx.png',
                alt: 'Logo Electronicx',
                link: 'https://electronicx.de/',
            },
            {
                image: '/assets/images/brand/gportal.png',
                alt: 'Logo GPORTAL',
                link: 'https://www.g-portal.com/',
            },
            {
                image: '/assets/images/brand/endstation.png',
                alt: 'Logo Endstation',
                link: 'https://www.endstation.beer/',
            },
        ]
    },
    techstack: {
        title: 'Tech Stack',
        description: 'Project Tampah trusts the newest technology to reach perfect results. Strong quality checks and reviews make sure that the code is readable and understandable. High test coverage makes sure, that it can be used continually and with success.',
        list: [
            {
                icon: <FaPython />,
                title: 'Python',
                description: 'High-level, interpreted, interactive and object-oriented scripting language.'
            },
            {
                icon: <DjangoIcon />,
                title: 'Django',
                description: 'Open source Webframework written in Python, following an MVP schema.'
            },
            {
                icon: <GraphQLIcon />,
                title: 'GraphQL',
                description: 'Query Language for our APIs, for the execution of requests by using a special type of system.'
            },
            {
                icon: <FaReact />,
                title: 'React',
                description: 'Hierarchic built JavaScript archive with self defined Syntax which combines all UI components.'
            },
            {
                icon: <NextJSIcon />,
                title: 'Next.js',
                description: 'Open source React Frontend Framework, which makes server-side redering as well as generating static Websites for React based web applications possible.'
            },
            {
                icon: <FaNodeJs />,
                title: 'Nodejs',
                description: 'Open-source, cross-platform, back-end JavaScript runtime environment, executes JavaScript code outside a web browser.'
            }
        ]
    },
    funfacts: [
        {
            countNum: 116,
            countTitle: 'Affiliate Partner',
        },
        {
            countNum: 1650,
            countTitle: 'Top 10 rankings on Google',
        },
        {
            countNum: 15,
            countTitle: 'People are working on our platforms.',
        },
    ],
};

import React, { Component } from "react";
import { data } from "../data";

class Header extends Component {
    render() {
        return (
            <header
                className={`header-area formobile-menu header--transparent color--black`}
            >
                <div className="header-wrapper" id="header-wrapper">
                    <div className="header-left">
                        <div className="logo">
                            <a href="/">
                                <img width="130" height="auto" src="/assets/images/logo/logo.png" alt="project tampah" />
                            </a>
                        </div>
                    </div>
                    <div className="header-right">
                        <div className="header-btn">
                            <a className="rn-btn" href={`mailto:${data.contact.email[0]}`}>
                                <span>Contact</span>
                            </a>
                        </div>
                    </div>
                </div>
            </header>
        );
    }
}

export default Header;

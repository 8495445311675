import React, { Component } from "react";
import { data } from "../../data";

class Footer extends Component {
    render() {
        return (
            <React.Fragment>
                <footer className="footer-area">
                    <div className="footer-wrapper">
                        <div className="row align-items-end row--0">
                            <div className="col-lg-6">
                                <div className="footer-left">
                                    <div className="inner">
                                        <span>Ready To Do This</span>
                                        <h2>Let's get <br /> to work</h2>
                                        <a className="rn-button-style--2" href={`mailto:${data.contact.email[0]}`}>
                                            <span>Contact Us</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="footer-right" data-black-overlay="6">
                                    <div className="row">
                                        <div className="col-lg col-sm col-6">
                                            <div className="footer-link">
                                                <h4>Quick Link</h4>
                                                <ul className="ft-link">
                                                    <li><a href="/#portfolio">Work</a></li>
                                                    <li><a href="/#about">About</a></li>
                                                    <li><a href={`mailto:${data.contact.email[0]}`}>Let's Talk</a></li>
                                                </ul>
                                            </div>
                                        </div>

                                        <div className="col-lg col-sm col-6">
                                            <div className="footer-link">
                                                <h4>Project Tampah</h4>
                                                <ul className="ft-link">
                                                    <li><a href="/imprint">Imprint</a></li>
                                                    <li><a href="/privacy-policy">Privacy Policy</a></li>
                                                </ul>
                                            </div>
                                        </div>

                                        <div className="col-lg-6 col-sm-6 col-12 mt_mobile--30">
                                            <div className="footer-link">
                                                <h4>Say Hello</h4>
                                                <ul className="ft-link">
                                                    {data.contact.email.map((value, i) => (
                                                        <li key={i}><a href={`mailto:${value}`}>{value}</a></li>
                                                    ))}
                                                </ul>

                                                <div className="social-share-inner">
                                                    <ul className="social-share social-style--2 d-flex justify-content-start liststyle mt--15">
                                                        {data.social.map((val, i) => (
                                                            <li key={i}><a href={`${val.link}`}>{val.social}</a></li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-12">
                                            <div className="copyright-text">
                                                <p>{data.copyright}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </React.Fragment>
        )
    }
}

export default Footer;
